.court-hub-button-primary {
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    background-color: #2191d0;
    color: white;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 15px;
    text-transform: uppercase;
}
.court-hub-button-default {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
}
.court-hub-button-primary:disabled,
.court-hub-button-default:disabled
{
    background-color: #cccccc;
    color: #66666681;
    cursor: not-allowed;
}