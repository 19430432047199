.carouselContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: relative;
    border-radius: 30px;
}
.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}
.imageContainer {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    border-radius: 30px;
}
.imageCarousel-image {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: opacity 0.4s ease-in-out;
    border-radius: 30px;
}
.arrowButtonLeft {
    position: absolute;
    top: 45%;
    left: 0;
    font-size: 30px;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    color: white;
    opacity: 0.7;
}
.arrowButtonRight {
    position: absolute;
    top: 45%;
    right: 0;
    font-size: 30px;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    color: white;
    opacity: 0.7;
}
.dotsContainer {
    position: absolute;
    top: 101%;
    margin-left: auto;
    margin-right: auto;
}
.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: gray;
    border-radius: 40%;
    display: inline-block;
    opacity: 0.5;
}