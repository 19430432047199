.bookBanner-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}
.bookBanner-homeBtnContainer {
    width: 30%;
    background-color: transparent;
}
.bookBanner-button {
    background-color: black;
    width: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    flex-direction: column;
    font-size: 14px;
}
.bookBanner-bookBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    background-color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.bookBanner-bookBtn button {
    background-color: #2491D0;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
    min-height: 70%;
    cursor: pointer;
    width: 80%;
}
.bookBanner-leagueBtn {
    color: white;
    width: 30%;
}
.bookBanner-transparent {
    width: 100%;
    height: 10%;
    background-color: transparent;
}
.bookBanner-icon {
    color: white;
}

@media only screen and (max-width: 450px) {
    .bookBanner-button {
        height: 90%;
    }
    .bookBanner-leagueBtn {
        width: 30%;
    }
}

@media only screen and (max-width: 375px) {
    .bookBanner-button {
        height: 90%;
    }
    .bookBanner-leagueBtn {
        width: 30%;
    }
}