.card-container {
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-imageContainer {
    width: 50%;
    border-radius: 8px;
    cursor: pointer;
    height: 200px;
}
.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.details {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding-left: 20px;
}
.courtName {
    display: flex;
    justify-content: start;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    min-height: 10%;
}
.courtInfo {
    display: flex;
    gap: 10px;
    font-size: 15px;
    color: gray;
    min-height: 10%;
}
.rating {
    display: flex;
}
.ratingText {
    margin-top: 3px;
}
.starIcon {
    color: orange;
}
.address {
    display: flex;
}
.addressText {
    margin-top: 3px;
}
.tags {
    display: flex;
    width: 100%;
    min-height: 30%;
    gap: 10px;
    flex-wrap: wrap;
    padding-top: 20px;
}
.tag {
    display: flex;
    align-items: center;
    background-color: lightgray;
    color: black;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    font-size: 10px;
    height: 20px;
}
.card-bookingInfo {
    display: flex;
    min-height: 10%;
    width: 90%;
    margin: auto;
    justify-content: space-between;
}
.card-price {
    display: flex;
    font-size: 17px;
    font-weight: 700;
    align-items: center;
}
.card-bookButton {
    align-items: center;
    background-color: #2191d0;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    border-radius: 10px;
}

.card-bookButton button {
    padding: 8px 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #2191d0;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    color: #fff; 
}

@media only screen and (max-width: 450px) {
    .card-container {
        flex-direction: column;
        border-radius: 10px;
        padding: 3px;
    }
    .card-imageContainer {
        width: 100%;
    }
    .card-imageContainer img {
        border-radius: 10px;
    }
    .details {
        flex-direction: column;
        width: 100%;
        padding-left: 5px;
    }
    .courtName {
        width: 100%;
    }
    .tags {
        padding-top: 10px;
    }
    .card-bookingInfo {
        width: 95%;
        margin-top: 10px;   
        margin-bottom: 5px;
    }
    .card-bookButton {
        padding-right: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .card-container {
        flex-direction: column;
        padding: 5px;
        border-radius: 10px;
    }
    .card-imageContainer {
        width: 100%;
    }
    .card-imageContainer img {
        border-radius: 10px;
    }
    .details {
        flex-direction: column;
        width: 100%;
        padding-left: 5px;
    }
    .courtName {
        width: 100%;
    }
    .tags {
        padding-top: 10px;
    }
    .card-bookingInfo {
        width: 95%;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .card-bookButton {
        padding-right: 10px;
    }
}