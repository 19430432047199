.topSection {
    width: 100%;
    height: 500px;
    background-image: url('../../images/hero_section.png');
    background-size: cover;
    background-position: center;
}

.navbar {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.logo {
    color: white;
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
}
.darklogo {
    color: black;
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
}

.authButtons {
    display: flex;
    justify-content: space-between;
    width: 20%;
}

.loggedInUserNavbar {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: end;
}

.logoutContainer {
    display: flex;
    justify-content: end;
    width: 20%;
}

.loginButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
}

.loginLink {
    text-decoration: none;
    color: #fff;
}

.registerButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
}

.registerLink {
    text-decoration: none;
    color: #fff;
}
.navbarBorder {
    display: flex;
    width: 100%;
    height: 2px;
    border-bottom: 2px solid lightgray;
}
.navbar-menu-item {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: black;
    font-size: 14px;
    font-weight: bold;
}
.navbar-hamburgerMenu {
    display: none;
    position: relative;
}
.navbar-hamburgerMenu-options {
    position: absolute;
    min-height: 100px;
    width: 120px;
    background-color: white;
    z-index: 100;
    top: 50px;
    left: -100px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    padding: 15px;
    gap: 10px;
}
.navbar-hamburgerMenu-icon-light {
    color: white;
}
.navbar-hamburgerMenu-options .mycourts-bookings-container {
    margin: 0px;
}

@media only screen and (max-width: 450px) {
    .loggedInUserNavbar {
        justify-content: end;
        align-items: center;
        width: 55%;
        display: none;
    }
    .navbar-hamburgerMenu {
        display: flex;
        position: relative;
    }
    
    .loggedInUserNavbar button {
        padding: 4px 8px;
        font-size: 12px;
        text-wrap: nowrap;
    }

    .myCourtsButton {
        padding: 4px 8px;
    }
}

@media only screen and (max-width: 375px) {
    .loggedInUserNavbar {
        justify-content: end;
        align-items: center;
        width: 60%;
        display: none;
    }

    .loggedInUserNavbar button {
        padding: 4px 8px;
        font-size: 12px;
        text-wrap: nowrap;
    }
    .myCourtsButton {
        padding: 4px 8px;
    }
}