.tournament-card-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;  
}

.tournament-card{
    position: relative;
    background: linear-gradient(108deg, rgba(33, 145, 208, 1) 35%, rgba(123, 180, 66, 1) 88%);
    border: 2px solid lightgray;
    border-radius: 15px;
    width: 80%;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
}
.tournament-card-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    background-image: url(../../../images/tour_background.svg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
}

.tournament-card-points {
    width: 20%;
    color: white;
    font-size: 55px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    font-family: "Inter", sans-serif;
    z-index: 30;
    margin-left: 30px;
}
.tournament-card-points-1000 {
    background-image: url(../../../images/tour_1000.svg);
}
.tournament-card-points-500 {
    background-image: url(../../../images/tour_500.svg);
}
.tournament-card-points-250 {
    background-image: url(../../../images/tour_250.svg);
}

.tournament-card-info {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Inter", sans-serif;
}

.tournament-card-info-box {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.tournament-card-info-courtName {
    display: flex;
    justify-content: start;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    font-family: "Poppins", sans-serif;
}

.tournament-card-info-address {
    font-size: 15px;
    color: white;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: left;
    height: 20px;
    align-items: start;
}

.tournament-card-info-city {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: left;
    align-items: end;
    height: 40px;
}

.tournament-card-dates-combined {
    display: none;
}
.tournament-card-dates {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 20px;
    width: 15%;
    justify-content: center;
    border: 1px solid white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}

.tournament-card-dates-days {
    color: white;
    font-size: 37px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.tournament-card-dates-month {
    color: white;
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.tournament-card-players {
    display: flex;
    flex-direction: column;
    width: 35%;
    color: white;
    font-family: "Inter", sans-serif;
}

.tournament-card-players-top {
    display: flex;
    flex-direction: row;
    justify-content: end;
    height: 50%;
    color: white;
    font-family: "Inter", sans-serif;
}

.tournament-card-players-top-box {
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.tournament-card-players-top-total {
    display: flex;
    justify-content: end;
    align-items: center;
}

.tournament-card-players-top-total-icon {
    color: white;
    display: flex;
    align-items: center;
}

.tournament-card-players-top-total-icon-svg {
    width: 45px !important;
    height: 45px !important;
}

.tournament-card-players-top-total-num {
    color: white;
    font-size: 25px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
}

.tournament-card-players-top-btn {
    width: 50%;
    display: flex;
    justify-content: end;
    margin-right: 30px;
}
.tournament-card-players-top-btn button {
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    text-transform: none;
    text-wrap: nowrap;
    z-index: 30;
}

.tournament-card-players-bottom {
    display: flex;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 26px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
    height: 50%;
}

.tournament-card-players-bottom-box {
    width: 80%;
    display: flex;
}
.tournament-card-border {
    border: 2px solid gray;
    margin-top: 10px;
}
.tournament-card-border {
    margin-top: 20px;
    width: 60%;
    height: 2px;
    background-color: rgb(168, 166, 166);
    border: 1px solid lightgray;
}

.join-tournament-modal-not-logged-in {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.join-tournament-modal-not-logged-in-message {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.join-tournament-modal-logged-in {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.join-tournament-modal-logged-in-options {
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.join-tournament-modal-logged-in-buttons {
    display: flex;
    flex-direction: row;
}
.join-tournament-confirmation-body-message {
    display: flex;
    justify-content: end;
}
.join-tournament-confirmation-body-buttons {
    display: flex;
    margin-top: 20px;
}

.join-tournament-modal-logged-in-terms {
    margin-top: 10px;
}

.join-tournament-modal-logged-in-terms-label {
    text-decoration: underline;
    cursor: pointer;
}

.join-tournament-terms-body-message-title {
    font-weight: 700;
    font-size: 17px;
}

@media only screen and (max-width: 450px) {
    .tournament-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 90%;
    }

    .tournament-card-points {
        width: 90%;
        height: 150px;
        margin-left: 0px;
    }

    .tournament-card-info {
        width: 100%;
    }

    .tournament-card-info-courtName {
        display: flex;
        justify-content: center;
    }

    .tournament-card-info-address {
        display: flex;
        justify-content: center;
    }

    .tournament-card-info-city {
        display: flex;
        justify-content: center;
    }

    .tournament-card-dates {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .tournament-card-dates-days {
        display: none;
    }

    .tournament-card-dates-month {
        display: none;
    }

    .tournament-card-dates-combined {
        font-size: 35px;
        font-weight: 700;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins", sans-serif;
    }

    .tournament-card-players {
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
    }

    .tournament-card-players-top {
        display: flex;
        justify-content: space-between;
    }

    .tournament-card-players-top-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .tournament-card-players-bottom {
        margin-top: 10px;
        font-size: 22px;
        ;
    }

    .tournament-card-players-bottom-box {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .tournament-card-players-top-btn {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 375px) {
    .tournament-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 90%;
    }

    .tournament-card-points {
        width: 90%;
        height: 150px;
        margin-left: 0px;
    }

    .tournament-card-info {
        width: 100%;
    }

    .tournament-card-info-courtName {
        display: flex;
        justify-content: center;
    }

    .tournament-card-info-address {
        display: flex;
        justify-content: center;
    }

    .tournament-card-info-city {
        display: flex;
        justify-content: center;
    }

    .tournament-card-dates {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .tournament-card-dates-days {
        display: none;
    }
    .tournament-card-dates-month {
        display: none;
    }
    .tournament-card-dates-combined {
        font-size: 35px;
        font-weight: 700;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins", sans-serif;
    }

    .tournament-card-players {
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
    }

    .tournament-card-players-top {
        display: flex;
        justify-content: space-between;
    }

    .tournament-card-players-top-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .tournament-card-players-bottom {
        margin-top: 10px;
        font-size: 22px;;
    }

    .tournament-card-players-bottom-box {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tournament-card-players-top-btn {
        margin-right: 0px;
    }

    .join-tournament-modal-not-logged-in-message {
        border: 1px solid green;
    }
}