.bookingManager-container {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    margin-top: 20px;
}
.controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}
.bookingManager-title {
    font-family: "Poppins", sans-serif;
    width: 35%;
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-top: -2px;
    justify-content: start;
    display: flex;
}
.bookingManager-header {
    display: flex;
    width: 30%;
    align-items: center;
    margin-bottom: 20px;
    justify-content: start;
    color: gray;
    font-weight: 400;
}
.bookingManager-tags {
    width: 30%;
    align-items: center;
}
.bookingInfo {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 20px;
    align-items: start;
}
.price {
    color: black;
    font-size: 17px;
    margin-top: 5px;
    font-weight: 700;
}
.bookButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}
.arrowButton {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: gray;
    font-weight: 400;
}
.dateRange {
    font-size: 12px;
    font-weight: bold;
}
.weekDays {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    text-transform: uppercase;
    color: black;
    margin-top: 30px;
}
.day {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-height: 400px;
    border-radius: 10px;
    width: 100%;
}
.dayName {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    color: black;
    font-weight: bold;
}
.bookings {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}
.bookedTime {
    width: 90%;
    display: flex;
    background-color: #d3d4d9;
    color: black;
    border-radius: 10px;
    font-weight: 700;
    font-size: 13px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}
.bookingsForTheDay {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

@media only screen and (max-width: 450px) {
    .bookingManager-container {
        width: 100%;
        flex-direction: column;
        margin-left: 0;
        margin-top: 5px;
    }
    .controls {
        width: 100%;
        flex-direction: column;
        padding: 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .bookingManager-title {
        display: none;
    }
    .bookingManager-header {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        justify-content: center;
    }
    .bookingInfo {
        display: none;
    }
    .weekDays {
        flex-direction: column;
    }
    .day {
        min-height: 100px;
    }
    .bookings {
        height: 100%;
        min-height: 80px;
        padding-bottom: 10px
    }
}

@media only screen and (max-width: 375px) {
    .bookingManager-container {
        width: 100%;
        flex-direction: column;
        margin-left: 0;
        margin-top: 5px;
    }
    .controls {
        width: 100%;
        flex-direction: column;
        padding: 0;
        margin-top: 5px; 
        margin-bottom: 5px;
    }
    .bookingManager-title {
        display: none;
    }
    .bookingManager-header {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        justify-content: center;
    }
    .bookingInfo {
        display: none;
    }
    .weekDays {
        flex-direction: column;
    }
    .day {
        min-height: 100px;
    }
    .bookings {
        height: 100%;
        min-height: 80px;
        padding-bottom: 10px
    }
}