.confirmationModal-modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.confirmationModal-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    color: black;
    font-weight: 700;
}
.confirmationModal-buttons {
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: end;
    gap: 20px;
}

.confirmationModal-cancel {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
}

.confirmationModal-confirm {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f60737ac;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}

@media only screen and (max-width: 450px) {
    .confirmationModal-modalContent {
        width: 80%;
        padding: 20px;
    }
}

@media only screen and (max-width: 375px) {
    .confirmationModal-modalContent {
        width: 80%;
        padding: 20px;
    }
}