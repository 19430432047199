.mycourts-bookings-container {
    display: flex;
    height: 30px;
    margin-right: 20px;
}
.mycourts-bookings-notifications {
    background-color: red;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    height: 25px;
    width: 25px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin-top: -5px;
}
.myCourtsButton {
    padding: 8px 16px;
    padding-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
}

.myCourtsButton-dark {
    padding: 8px 16px;
    padding-right: 0px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: black;
    font-size: 14px;
    font-weight: bold;
}

.mycourts-contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.mycourts-reservationTitleBox {
    width: 80%;
    height: 200px;
    min-height: 200px;
    border-radius: 10px;
    background-color: pink;
    display: flex;
    align-self: center;
    margin-top: 30px;
    position: relative;
}
.mycourts-reservationTitle {
    position: absolute;
    width: 50%;
    top: 10%;
    left: 10px;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 900;
    color: white;
    z-index: 10;
    text-align: left;
    padding-left: 50px;
}
.mycourts-reservationImage {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: darkblue;
    opacity: 0.7;
}
.mycourts-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    opacity: 0.7;
}
.mycourts-courtList {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 30px;
}
.mycourt-courtCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.mycourt-title {
    font-size: 35px;
    color: black;
    font-weight: 700;
    text-align: left;
    cursor: pointer;
}
.mycourt-ratingAndAddress {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.mycourt-address {
    color: gray;
}
.mycourt-turfs-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 25px;
}
.mycourt-turfs {
    width: 80%;
    display: flex;
    flex-direction: start;
    gap: 10px;
    align-items: center;
}
.mycourt-turfBtn {
    display: flex;
    flex-direction: row;
    gap: 3px;
    padding: 4px 8px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    background-color: lightgray;
}
.mycourt-turfBtn-name {
    pointer-events: none;
}
.mycourt-turfBtn-number {
    pointer-events: none;
    color: red;
}
.mycourt-addBooking {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5px;
}
.mycourt-addBooking button {
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    background-color: #2191d0;
    color: white;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 2px;
}
.mycourt-addBooking button:hover {
    opacity: 0.7;
}
.mycourt-bookingsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
}
.mycourt-booking {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.mycourt-bookingCard {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.mycourt-bookingDate {
    width: 100%;
    display: flex;
    justify-content: start;
    color: black;
    font-weight: 700;
    margin-bottom: 10px;
}
.mycourt-bookingRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    gap: 5px;
}
.mycourt-bookingRow-timeSlot {
    background-color: lightgray;
    color: black;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    width: 20%;
}
.mycourt-bookingRow-timeSlot-accepted {
    border: 1px solid black;
}
.mycourt-bookingRow-timeSlot.reservationStatusAccepted {
    background-color: lightgray;
    color: black;
    background-color: #2191d0;
    color: white;
    cursor: pointer;
}
.mycourt-bookingRow-timeSlot.reservationStatusAccepted:hover {
    background-color: #2190d0ad;
}

.mycourt-bookingRow-timeSlot.reservationStatusRejected {
    background-color: lightgray;
    color: black;
}

.mycourt-bookingRow-timeSlot.reservationStatusRequested {
    background-color: lightgray;
    color: black;
}
.mycourts-reservationStatus {
    width: 20%;
}
.reservationStatusRequested {
    background-color: lightgray;
    color: black;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    width: 20%;
}
.reservationStatusAccepted {
    background-color: #7BB542;
    color: white;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
}
.reservationStatusRejected {
    background-color: red;
    color: white;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
}
.mycourt-bookingRow-requestedBy {
    background-color: lightgray;
    color: black;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    width: 30%;
}
.mycourt-bookingRow-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 10px;
    width: 25%;
}
.mycourt-bookingRow-buttons :disabled {
    opacity: 0.5;
}
.mycourt-bookingRow-buttons button[disabled]:hover {
    opacity: 0.5;
}
.mycourt-bookingRow-buttons :hover {
    opacity: 0.8;
}
.mycourt-bookingRow-buttons-accept {
    background-color: #7BB542;
    color: white;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}
.mycourt-bookingRow-buttons-reject {
    background-color: transparent;
    color: red;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}
.mycourt-bookingRow-buttons-remove {
    background-color: #dc3545;
    color: white;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.mycourt-error {
    width: 100%;
    color: red;
    margin-top: 20px;
    font-weight: 700;
}
.mycourt-error-btn {
    background-color: lightgray;
    color: black;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 20px;
    margin-left: 20px;
    border: none;
    cursor: pointer;
}
.mycourt-error-btn:hover {
    opacity: 0.7;
}
.mycourt-recurringBookings {
    margin-top: 40px;
    margin-bottom: 20px
}
.mycourt-recurringBookings-title {
    color: black;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 15px;
}

@media only screen and (max-width: 450px) {
    .mycourts-bookings-container {
        margin-right: 50px;
    }
    .mycourts-bookings-notifications {
        font-size: 8px;
        width: 19px;
        height: 19px;
        margin-top: -2px;
    }
    .mycourts-reservationTitleBox {
        width: 95%;
        height: 150px;
        min-height: 150px;
    }

    .mycourts-reservationTitle {
        font-size: 50px;
        width: 100%;
        padding-left: 5px;
        left: 0px;
    }

    .mycourts-courtList {
        width: 95%;
    }
    .mycourt-addBooking {
        width: 35%;
    }
    .mycourt-addBooking button {
        min-width: 50px;
        justify-content: center;
    }
    .mycourt-addBooking-text {
        display: none;
    }
    .mycourt-turfs {
        overflow-y: scroll;
    }

    .mycourt-bookingRow {
        gap: 0px;
        justify-content: left;
        font-size: 12px;
    }

    .mycourt-bookingRow-timeSlot {
        display: flex;
        padding: 4px 0px;
        border-radius: 0px;
        justify-content: left;
        width: 30%;
        min-width: 20px;
        align-items: center;
        padding-left: 2px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: bold;
        text-wrap: nowrap;
    }

    .mycourt-bookingRow-timeSlot.reservationStatusAccepted {
        background-color: green;
        color: white;
        opacity: 0.4;
    }

    .mycourt-bookingRow-timeSlot.reservationStatusRejected {
        background-color: red;
        color: white;
        opacity: 0.4;
    }

    .mycourt-bookingRow-timeSlot.reservationStatusRequested {
        background-color: lightgray;
        color: black;
        opacity: 1;
    }

    .mycourts-reservationStatus {
        display: none;
    }

    .mycourt-bookingRow-requestedBy {
        text-wrap: wrap;
        width: 50%;
        display: flex;
        justify-content: left;
        padding-left: 5px;
        align-items: center;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-weight: bold;
        overflow: hidden;
    }

    .mycourt-bookingRow-requestedBy.reservationStatusAccepted {
        background-color: green;
        color: white;
        opacity: 0.4;
    }

    .mycourt-bookingRow-requestedBy.reservationStatusRejected {
        background-color: red;
        color: white;
        opacity: 0.4;
    }

    .mycourt-bookingRow-requestedBy.reservationStatusRequested {
        background-color: lightgray;
        color: black;
        opacity: 1;
    }

    .mycourt-bookingRow-buttons {
        width: 30%;
    }

    .mycourt-bookingRow-buttons-reject {
        width: 50%;
        min-width: 20px;
        color: transparent;
    }

    .mycourt-bookingRow-buttons-reject:after {
        color: red;
        content: "\00d7";
        display: inline-block;
        font-size: 30px;
        width: 10px;
        height: 10px;
        margin-top: -20px;
    }

    .mycourt-bookingRow-buttons-accept {
        width: 50%;
        min-width: 20px;
        color: transparent;
        position: relative;
    }

    .mycourt-bookingRow-buttons-accept:after {
        position: absolute;
        color: white;
        content: "\2713";
        display: flex;
        font-size: 25px;
        width: 5px;
        height: 5px;
        top: 5px;
        left: 15px;
    }
    .mycourt-bookingRow-buttons-remove {
        width: 50%;
        min-width: 20px;
        color: transparent;
        background-color: transparent;
    }

    .mycourt-bookingRow-buttons-remove:after {
        color: red;
        content: "\00d7";
        display: inline-block;
        font-size: 30px;
        width: 10px;
        height: 10px;
        margin-top: -20px;
    }
}

@media only screen and (max-width: 375px) {
    .mycourts-bookings-notifications {
        font-size: 8px;
        width: 17px;
        height: 17px;
        margin-top: -2px;
    }
    .mycourts-reservationTitleBox {
        width: 95%;
        height: 150px;
        min-height: 150px;
    }
    .mycourts-reservationTitle {
        font-size: 50px;
        width: 100%;
        padding-left: 5px;
        left: 0px;
    }
    .mycourts-courtList {
        width: 95%;
    }
    .mycourt-addBooking {
        width: 35%;
    }

    .mycourt-turfs {
        overflow-y: scroll;
    }

    .mycourt-addBooking button {
        min-width: 50px;
        justify-content: center;
    }

    .mycourt-addBooking-text {
        display: none;
    }

    .mycourt-bookingRow {
        gap: 0px;
        justify-content: left;
        font-size: 12px;
    }
    .mycourt-bookingRow-timeSlot {
        display: flex;
        padding: 4px 0px;
        border-radius: 0px;
        justify-content: left;
        width: 30%;
        min-width: 20px;
        align-items: center;
        padding-left: 2px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: bold;
        overflow: hidden;
    }
    .mycourt-bookingRow-timeSlot.reservationStatusAccepted {
        background-color: green;
        color: white; 
        opacity: 0.4;
    }
    .mycourt-bookingRow-timeSlot.reservationStatusRejected {
        background-color: red;
        color: white;
        opacity: 0.4;
    }
    .mycourt-bookingRow-timeSlot.reservationStatusRequested {
        background-color: lightgray;
        color: black;
        opacity: 1;
    }
    .mycourts-reservationStatus {
        display: none;
    }
    .mycourt-bookingRow-requestedBy {
        width: 50%;
        display: flex;
        justify-content: left;
        padding-left: 5px;
        align-items: center;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-weight: bold;
    }
    .mycourt-bookingRow-requestedBy.reservationStatusAccepted {
        background-color: green;
        color: white;
        opacity: 0.4;
    }

    .mycourt-bookingRow-requestedBy.reservationStatusRejected {
        background-color: red;
        color: white;
        opacity: 0.4;
    }

    .mycourt-bookingRow-requestedBy.reservationStatusRequested {
        background-color: lightgray;
        color: black;
        opacity: 1;
    }
    .mycourt-bookingRow-buttons {
        width: 30%;
    }
    .mycourt-bookingRow-buttons-reject {
        width: 50%;
        min-width: 20px;
        color: transparent;
        position: relative;
    }
    .mycourt-bookingRow-buttons-reject:after {
        color: red;
        content: "\00d7";
        display: inline-block;
        font-size: 30px;
        position: absolute;
        width: 5px;
        height: 5px;
        top: 12px;
        left: 20px;
    }
    .mycourt-bookingRow-buttons-accept {
        width: 50%;
        min-width: 20px;
        color: transparent;
        position: relative;
    }
    .mycourt-bookingRow-buttons-accept:after {
        position: absolute;
        color: white;
        content: "\2713";
        display: flex;
        font-size: 20px;
        width: 5px;
        height: 5px;
        top: 0px;
        left: 15px;
    }
    .mycourt-bookingRow-buttons-remove {
        width: 50%;
        min-width: 20px;
        color: transparent;
        background-color: transparent;
    }

    .mycourt-bookingRow-buttons-remove:after {
        color: red;
        content: "\00d7";
        display: inline-block;
        font-size: 30px;
        width: 10px;
        height: 10px;
        margin-top: -20px;
    }
}