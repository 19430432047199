.bookingModal-modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.bookingModal-text {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
    color: rgb(75, 75, 75)
}
.bookingModal-datePicker {
    width: 100%;
    display: flex;
    justify-content: center;
}
.bookingModal-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    color: black;
    font-weight: 700;
}
.bookingModal-arrowButton {
    font-size: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: black;
    font-weight: 400;
}
.bookingModal-timeBox {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 3px;
}
.bookingModal-timeSlot {
    display: flex;
    justify-content: center;
    width: 20%;
    border-top: 2px solid #007BFF;
    border-bottom: 2px solid #007BFF;
    padding: 10px 0px 10px 0px;
    font-size: 20px;
    font-weight: 700;
}
.bookingModal-timeSelect {
    font-size: 20px;
    font-weight: 700;
}
.bookingModal-separator {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}
.bookingModal-formGroup {
    margin-bottom: 15px;
}
.bookingModal-timePicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bookingModal-buttons {
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: end;
}
.bookingModal-cancel {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
}
.bookingModal-confirm {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}
.bookingModal-message {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.bookingModal-userBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
    margin-top: 30px;
    align-items: center;
}
.bookingModal-userLabel {
    color: black;
    width: 40%;
    font-size: 15px;
}
.bookingModal-userInput {
    width: 60%;
    height: 90%;
    font-size: 13px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.bookingModal-recurringSection {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 0px;
}
.bookingModal-recurringSection-input {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    gap: 5px;
    align-items: center;
}
.bookingModal-recurringSection-input input {
    padding: 0px;
    margin: 0px;
}
.bookingModal-recurringOptions {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
}
.bookingModal-recurringOptions label {
    font-size: 15px;
}
.bookingModal-recurringOptions-days {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.bookingModal-recurringOptions-days button {
    background-color: lightgray;
    border: 1px solid darkgray;
    color: white;
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 450px) {
    .bookingModal-modalContent {
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .bookingModal-text {
        text-align: center;
        font-size: 15px;
    }
    
    .bookingModal-userBox {
        flex-direction: column;
        gap: 5px;
        margin-bottom: 20px;
    }

    .bookingModal-userLabel {
        width: 100%;
    }

    .bookingModal-userInput {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media only screen and (max-width: 375px) {
    .bookingModal-modalContent {
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .bookingModal-text {
        text-align: center;
        font-size: 15px;
    }
    .bookingModal-userBox {
        flex-direction: column;
        gap: 5px;
        margin-bottom: 20px;
    }
    .bookingModal-userLabel {
        width: 100%;
    }
    .bookingModal-userInput {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}