.court-contentContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.court-scrollableContent {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.court-bannerContainer {
    display: none;
}
.court-topSection {
    width: 100%;
    height: 500px;
}
.image-carousel {
    display: flex;
    width: 80%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 450px;
}

.courtInfoContainer {
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
    flex-direction: column;
}
.courtInformation {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: end;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}
.court-courtAdditionalInformation {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
}

.courtAdditionalContent {
    display: flex;
    flex-direction: column;
    width: 30%;
}
.court-courtName {
    font-family: "Poppins", sans-serif;
    width: 100%;
    color: black;
    font-size: 30px;
    font-weight: bold;
    display: flex;
}
.courtRatingAndAddressContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 5px;
}
.courtRating {
    display: flex;
    justify-content: left;
    width: 20%;
    gap: 15px;
}
.ratingNumber {
    color: black;
    font-weight: 700;
    font-size: 15px;
    margin-top: 1px;
}
.courtAddress {
    display: flex;
    justify-content: left;
    width: 80%;
    color: gray;
}
.placeIcon {
    margin-top: -2px;
    color: black;
}
.courtTags {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 30%;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}
.court-tag {
    display: flex;
    align-items: center;
    background-color: lightgray;
    color: black;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    font-size: 10px;
    height: 20px;
    font-weight: 700;
}
.courtTags-mobile {
    display: none;
}
.courtContent {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: black;
    font-weight: bold;
    justify-content: end;
}
.contentList {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.contentItem {
    display: flex;
    flex-direction: row;
    gap: 2px;
}
.contentIcon {
    color: gray;
    font-size: 15px;
}
.contentText {
    color: black;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 300;
}
.availableTurfs {
    display: flex;
    justify-content: start;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: bold;
}
.courtTurf {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    gap: 20px;
}
.turf {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
}
.bookingInfoContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
}
.calendarInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.bookingInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.bookingCalendar {
    display: flex;
    width: 80%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    background-color: lightgray;
    border-radius: 10px;
}
.court-bookingInfo {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
    align-items: center;
    justify-content: end;
    margin-top: 20px
}
.court-bookButton {
    padding: 8px 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #2191d0;
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #fff;
}
.court-price {
    font-family: "Inter", sans-serif;
    font-size: 25px;
    font-weight: bold;
}
.announcements {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: start;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}
.announcementTitle {
    width: 100%;
    font-size: 25px;
    font-weight: 700;
    color: black;
    display: flex;
    justify-content: start;
    margin-top: 30px;
}
.announcementsContent {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.announcementItem {
    display: flex;
    justify-content: start;
    width: 100%;
    color: gray;
    margin-bottom: 10px;
}

@media only screen and (max-width: 450px) {
    .court-scrollableContent {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .court-bannerContainer {
        width: 100%;
        height: 10%;
        display: flex;
    }

    .courtInfoContainer {
        width: 95%;
        flex-direction: column;
        min-height: 20px;
        margin-bottom: 20px;
    }

    .courtInformation {
        padding-top: 0px;
        height: 20px;
        margin-top: 20px;
        width: 100%;
    }
    .court-courtName {
        display: none;
    }

    .courtRating {
        display: none;
    }

    .courtAdditionalContent {
        width: 100%;
        margin-top: 0px;
    }
    .courtRatingAndAddressContainer {
        display: none;
    }

    .court-courtAdditionalInformation {
        width: 100%;
    }

    .contentList {
        margin-top: 5px;
        justify-content: start;
    }

    .contentItem {
        align-items: center;
    }

    .contentItem svg {
        width: 15px;
        height: 15px;
    }

    .courtContent {
        justify-content: start;
        width: 100%;
    }

    .availableTurfs {
        display: flex;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
        color: black;
        font-weight: 700;
        justify-content: start;
        margin-bottom: 0px;
    }

    .courtTurf {
        height: 30px;
        margin-top: 5px;
        width: 95%;
        justify-content: start;
        overflow-x: scroll;
        gap: 5px;
    }

    .turf {
        padding: 4px 4px;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        align-items: center;
        display: flex;
        min-width: 60px;
        justify-content: center;
    }
    .courtTags {
        display: none;
    }
    .courtTags-mobile {
        display: flex;
        margin-top: 10px;
    }
    .courtTags-mobile .courtTags {
        display: flex;
        padding-top: 5px;
        justify-content: center;
    }
    .court-bookingInfo {
        display: none
    }
    .bookingInfoContainer {
        width: 95%;
    }

    .calendarInfo {
        width: 100%;
    }

    .announcements {
        width: 95%;
        margin-top: 20px;
    }

    .image-carousel {
        height: 200px;
        width: 100%;
        border-radius: 0px;
        margin-top: 0px;
    }

    .image-carousel img {
        height: 100%;
        border-radius: 0px;
    }
}

@media only screen and (max-width: 375px) {
    .court-scrollableContent {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
    .court-bannerContainer {
        width: 100%;
        height: 10%;
        display: flex;
    }
    .courtInfoContainer {
        width: 95%;
        flex-direction: column;
        min-height: 20px;
        margin-bottom: 20px;
    }
    .courtInformation {
        padding-top: 0px;
        height: 20px;
        margin-top: 20px;
    }
    .courtRating {
        display: none;
    }
    .courtAddress {
        display: none;
    }
    .courtAdditionalContent {
        width: 100%;
        margin-top: 0px;
    }
    .courtRatingAndAddressContainer {
        display: none;
    }
    .court-courtAdditionalInformation {
        width: 100%;
    }
    .contentList {
        margin-top: 5px;
        justify-content: start;
    }
    .contentItem {
        align-items: center;
    }
    .contentItem svg {
        width: 15px;
        height: 15px;
    }
    .courtContent {
        justify-content: start;
    }
    .availableTurfs {
        display: flex;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
        color: black;
        font-weight: 700;
        justify-content: start;
        margin-bottom: 0px;
    }
    .courtTurf {
        height: 30px;
        margin-top: 5px;
        width: 95%;
        justify-content: start;
        overflow-x: scroll;
        gap: 5px;
    }
    .turf {
        padding: 4px 4px;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        align-items: center;
        display: flex;
    }
    .courtTags-mobile {
        display: flex;
        margin-top: 10px;
    }
    .courtTags-mobile .courtTags {
        display: flex;
        padding-top: 5px;
        justify-content: center;
    }
    .bookingInfoContainer {
        width: 95%;
    }
    .calendarInfo {
        width: 100%;
    }
    .announcements {
        width: 95%;
        margin-top: 20px;
    }
    .image-carousel {
        height: 200px;
        width: 100%;
        border-radius: 0px;
        margin-top: 0px;
    }
    .image-carousel img {
        height: 100%;
        border-radius: 0px;
    }
}