.heroSectionTitle {
    width: 50%;
    margin: auto;
    margin-top: 100px;
    height: 150px;
    color: white;
    font-size: 64px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-family: "Poppins",
        sans-serif;
    line-height: 70px;
}

.heroSectionSubtitle {
    width: 50%;
    margin: auto;
    height: 150px;
    color: white;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    font-family: "Inter",
        sans-serif;
}
.courts-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.selectionBox {
    display: flex;
    width: 80%;
    position: absolute;
    z-index: 1000;
    top: 400px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 180px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    justify-content: center;
    align-items: center;
}
.selectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 90%;
}
.selectionTitle {
    display: flex;
    margin-top: 10px;
    width: 100%;
    color: black;
    font-size: 12px;
    font-weight: 700;
}

.bookmarkIcon {
    margin-top: -5px;
    margin-left: -5px;
}
.selectorsBox {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.selectors {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    font-size: 15px;
}

.dropdownBox {
    width: 25%;
}

.searchButton {
    display: flex;
    width: 25%;
    height: 40px;
    justify-content: right;
}
.searchButton button {
    width: 100%;
    background-color: #2191d0;
    font-family: "Inter", sans-serif;
    font-size: 18px;
}

.topNav {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
}

.heading {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 30px;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    text-align: left;
}
.courts-filtersBtn {
    display: none;
}

.filters {
    width: 25%;
}
.courts {
    width: 75%;
    padding: 16px;
    padding-right: 0px;
}
.pagedCourts-moreCourtsBtn {
    padding: 8px 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: #2191d0;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    color: #fff;
}

@media only screen and (max-width: 450px) {
    .loggedInUserNavbar {
        justify-content: space-between;
        align-items: center;
    }

    .loggedInUserNavbar button {
        padding: 4px 8px;
        font-size: 12px;
        text-wrap: nowrap;
    }

    .heroSectionTitle {
        font-size: 35px;
        width: 90%;
        margin-top: 80px;
        height: 50px;
        line-height: 45px;
    }

    .heroSectionSubtitle {
        display: none;
    }

    .topSection {
        height: 350px;
    }

    .selectionBox {
        top: 300px;
        height: 270px;
        width: 90%;
    }

    .selectorsBox {
        flex-direction: column;
        gap: 3px;
    }
    .dropdownBox {
        width: 100%;
    }

    .selectors {
        flex-direction: column;
        width: 100%;
    }

    .selectionTitle {
        width: 100%;
    }

    .searchButton {
        width: 100%;
        justify-content: center;
    }

    .heading {
        margin-top: 80px;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .heading h2 {
        font-size: 30px;
    }
    .heading .btnPrimary {
        padding: 0px;
        font-size: 15px;
        min-height: 40px;
    }
    .courts-filtersBtn {
        width: 100px;
        max-height: 20px;
        display: block;
    }
    .content {
        flex-direction: column-reverse;
        width: 90%;
        position: relative;
    }
    .filters {
        position: absolute;
        top: -5px;
        left: -20px;
        z-index: 20;
        background: white;
        width: 80%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-left: 20px;
    }
    .hideFiltersOnMobile {
        display: none;
    }
    .courts {
        width: 100%;
        padding: 0;
    }
}

@media only screen and (max-width: 375px) {
    .loggedInUserNavbar {
        justify-content: space-between;
        align-items: center;
    }
    .loggedInUserNavbar button {
        padding: 4px 8px;
        font-size: 12px;
        text-wrap: nowrap;
    }
    .heroSectionTitle {
        font-size: 35px;
        width: 90%;
        margin-top: 80px;
        height: 50px;
        line-height: 45px;
    }
    .heroSectionSubtitle {
        display: none;
    }
    .topSection {
        height: 350px;
    }
    .selectionBox {
        top: 300px;
        height: 270px;
        width: 90%;
    }
    .selectorsBox {
        flex-direction: column;
        gap: 3px;
    }
    .selectors {
        flex-direction: column;
        width: 100%;
    }
    .selectionTitle {
        width: 100%;
    }
    .searchButton {
        width: 100%;
        justify-content: center;
    }
    .heading {
        margin-top: 90px;
        width: 90%;
    }
    .heading h2 {
        font-size: 25px;
    }
    .heading .btnPrimary {
        padding: 0px;
        font-size: 15px;
        min-height: 30px;
    }

    .courts-filtersBtn {
        width: 100px;
        max-height: 20px;
        display: block;
    }
    .content {
        flex-direction: column-reverse;
        width: 90%;
        position: relative;
    }
    .filters {
        position: absolute;
        top: -5px;
        left: -20px;
        z-index: 20;
        background: white;
        width: 80%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-left: 20px;
    }
    .hideFiltersOnMobile {
        display: none;
    }
    .courts {
        width: 100%;
        padding: 0;
    }
}