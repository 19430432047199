.court-hub-modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.court-hub-modal-body {

}
.court-hub-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media only screen and (max-width: 450px) {
    .court-hub-modalContent {
        width: 90%;
        padding: 40px 20px 40px 20px;
    }
}

@media only screen and (max-width: 375px) {
    .court-hub-modalContent {
        width: 90%;
        padding: 40px 20px 40px 20px;
    }
}