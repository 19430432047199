.card {
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.mainContainer {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    min-height: 100vh;
}
.logoImage {
    min-width: 70%;
    background-image: url('../../images/login_background.jpg');
    background-size: cover;
    background-position: center center;
    object-fit: scale-down;
}
.registerSection {
    min-width: 30%;
    background-color: #111b3c;
    display: flex;
    justify-content: center;
}
.registerFormSection {
    margin-top: 30px;
    width: 50%;
    color: white;
}
.registrationHeader {
    display: flex;
    flex-direction: column
}
.auth-header {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}
.headerTitle {
    margin-bottom: 3px;
}
.headerInfo {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: slnt 0;
    font-size: 12px;
    color: #2491D0;
}
.formGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.formLabel {
    margin-bottom: 10px;
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    text-align: left;
}
.formTextInput {
    background-color: #0f193c;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    box-shadow: none;
    color: white;
}
.btnPrimary {
    background-color: #2491D0;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    border: none;
    border-radius: 8px;
    min-height: 30px;
    cursor: pointer;
}
.promotionSpan {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "slnt 0";
    font-size: 12px;
    color: #84878B;
}
.googleAuth {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
}
.googleAuthLabel {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "slnt 0";
    font-size: 12px;
    color: #84878B;
}
.googleAuthButton {
    background-color: white;
    color: #84878B;
    font-family: "Inter" sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "slnt 0";
    font-size: 12px;
    border: none;
    border-radius: 8px;
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.gsiMaterialButtonIcon {
    height: 15px;
    margin-right: 12px;
    min-width: 15px;
    width: 15px;
    margin: 0;
    padding: 9px;
}
.errorMessage {
    color: red;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-size: 12px;
}
.registerForm-errorMessage {
    color: rgba(255, 2, 2, 0.83);
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 10px;
    margin-top: 3px;
    text-align: left;
}
.forgot-password {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    font-size: 13px;
}

@media only screen and (max-width: 450px) {
    .mainContainer {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
    }
    .logoImage {
        min-height: 40%;
    }
    .registrationHeader {
        display: flex;
        flex-direction: column;
    }
    .registerSection {
        min-width: 30%;
        height: 100%;
        background-color: #111b3c;
        display: flex;
    }
    .registerFormSection {
        margin-top: 10px;
        min-width: 70%;
        color: white;
    }
    .auth-header {
        font-size: 25px
    }
    .auth-header h2 {
        margin-bottom: 10px;
    }
    .authButtons {
        width: 100%;
        justify-content: end;
    }
    .authButtons button {
        padding: 4px 8px;
        font-size: 13px;
    }
    .headerInfo {
        font-size: 18px;
    }
    .formLabel {
        font-size: 18px
    }
    .formTextInput {
        font-size: 15px
    }
    .btnPrimary {
        font-size: 18px;
        min-height: 50px;
    }
    .googleAuth {
        margin-bottom: 30px;
    }
    .googleAuthLabel {
        font-size: 15px
    }
    .googleAuthButton {
        font-size: 18px;
        min-height: 50px;
    }
    .errorMessage {
        font-size: 18px;
    }
}

@media only screen and (max-width: 375px) {
    .mainContainer {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
    }
    .logoImage {
        min-height: 40%;
    }
    .registerFormSection {
        margin-top: 10px;
        min-width: 70%;
        color: white;
    }
    .registerSection {
        height: 120%;
    }
    .auth-header {
        font-size: 20px
    }
    .auth-header h2 {
        margin-bottom: 10px;
    }
    .authButtons {
        width: 100%;
        justify-content: end;
    }
    .authButtons button {
        padding: 4px 8px;
        font-size: 13px;
    }
    .headerInfo {
        font-size: 15px;
    }
    .formLabel {
        font-size: 15px
    }
    .formTextInput {
        font-size: 15px
    }
    .btnPrimary {
        font-size: 15px;
        min-height: 50px;
    }
    .googleAuth {
        margin-bottom: 30px;
    }
    .googleAuthLabel {
        font-size: 15px
    }
    .googleAuthButton {
        font-size: 15px;
        min-height: 50px;
    }
    .errorMessage {
        font-size: 18px;
    }
}

