.footer {
    width: 100%;
    height: 100px;
    background-color: #131c3d;
    margin-top: 20px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer-copyright {
    color: rgb(188, 188, 188);
    font-family: "Inter", sans-serif;
    font-size: 17px;
    width: 100%;
}

.footer-info {
    color: rgb(188, 188, 188);
    font-family: "Inter", sans-serif;
    font-size: 17px;
    width: 100%;
}

@media only screen and (max-width: 450px) {
    .footer {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 375px) {
    .footer {
        margin-top: 50px;
    }
}