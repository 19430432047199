.myprofile-contentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.myprofile-playerInfoBox {
    width: 80%;
    height: 200px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: end;
    margin-top: 50px;
}
.myprofile-playerInfoBox-logobox {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: end;
    background-color: darkblue;
    opacity: 0.7;
    border-radius: 20px;
    background-position: left;
}
.myprofile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    opacity: 0.7;
}
.myprofile-playerInfoBox-name {
    position: absolute;
    top: 30%;
    width: 75%;
    display: flex;
    align-items: end;
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
    color: white;
}
.myprofile-playerInfoBox-playerImage {
    border: 1px solid blue;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50px;
    top: 30px;
    background-color: cadetblue;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myprofile-playerInfoBox-playerImage-icon {
    width: 200px !important;
    height: 200px !important;
    color: lightblue;
}
.myprofile-playerInfoBox-matches {
    height: 40%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.myprofile-playerInfoBox-matches-count {
    width: 15%;
    height: 75%;
    display: flex;
    font-size: 30px;
    font-weight: bold;
    justify-content: center;
    margin-top: 20px;
}
.myprofile-playerInfoBox-matches-title {
    width: 15%;
    height: 25%;
    display: flex;
    font-size: 15px;
    justify-content: center;
}
.myProfile-bookingsBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: start;
}
.myProfile-bookings-title {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.myProfile-bookingsList {
    display: flex;
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
}
.myProfile-bookings-booking {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.myProfile-bookings-bookingDate {
    display: flex;
    width: 100%;
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
}
.myProfile-bookings-bookingInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: start;
    margin-bottom: 30px;
}
.myProfile-bookings-bookingInfo-time {
    background-color: lightgray;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 30%;
    display: flex;
    justify-content: start;
    padding: 10px;
}
.myProfile-bookings-bookingInfo-user {
    background-color: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 50%;
    display: flex;
    justify-content: start;
    padding: 10px;
}
.myProfile-bookings-bookingInfo-button {
    width: 20%;
    display: flex;
    justify-content: end;
}
.myProfile-bookings-bookingInfo-button button {
    background-color: transparent;
    color: red;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 5px;
    min-width: 100px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}
.myProfile-bookings-bookingInfo-button button:disabled {
    opacity: 0.4;
}

@media only screen and (max-width: 450px) {
    .myprofile-playerInfoBox {
        width: 95%;
        align-items: center;
    }
    .myprofile-playerInfoBox-logobox {
        height: 100px;
    }
    .myprofile-playerInfoBox-playerImage {
        width: 120px;
        height: 120px;
        left: 20px;
        top: 30px;
    }
    .myprofile-playerInfoBox-playerImage-icon {
        width: 120px !important;
        height: 120px !important;
    }
    .myprofile-playerInfoBox-name {
        width: 60%;
        margin-bottom: 10px;
    }
    .myprofile-playerInfoBox-matches {
        width: 45%;
        align-items: end;
    }
    .myprofile-playerInfoBox-matches-count {
        width: 80%;
        margin-top: 5px;
        height: 50%;
    }
    .myprofile-playerInfoBox-matches-title {
        width: 80%;
    }
    .myProfile-bookingsBox {
        width: 95%;
        margin-top: 30px;
    }
    .myProfile-bookings-bookingInfo {
        font-size: 13px;
    }
    .myProfile-bookings-bookingInfo-user {
        width: 60%;
    }
    .myProfile-bookings-bookingInfo-button {
        width: 10%;
    }
    .myProfile-bookings-bookingInfo-button button {
        width: 100%;
        min-width: 20px;
        color: transparent;
    }

    .myProfile-bookings-bookingInfo-button button:after {
        color: red;
        content: "\00d7";
        display: inline-block;
        font-size: 30px;
        width: 10px;
        height: 10px;
        margin-top: -20px;
    }
}

@media only screen and (max-width: 375px) {
    .myprofile-playerInfoBox {
        width: 95%;
        height: 150px;
    }

    .myprofile-playerInfoBox-logobox {
        height: 100px;
    }

    .myprofile-playerInfoBox-playerImage {
        width: 100px;
        height: 100px;
        left: 20px;
        top: 30px;
    }

    .myprofile-playerInfoBox-playerImage-icon {
        width: 100px !important;
        height: 100px !important;
    }

    .myprofile-playerInfoBox-name {
        width: 60%;
        margin-bottom: 10px;
    }

    .myprofile-playerInfoBox-matches {
        width: 50%;
    }

    .myprofile-playerInfoBox-matches-count {
        width: 80%;
        margin-top: 5px;
        font-size: 20px;
        height: 30px;
    }

    .myprofile-playerInfoBox-matches-title {
        width: 80%;
        font-size: 13px;
    }

    .myProfile-bookingsBox {
        width: 95%;
        margin-top: 30px;
    }
    .myProfile-bookings-bookingInfo {
        font-size: 13px;
    }

    .myProfile-bookings-bookingInfo-button {
        width: 10%;
    }

    .myProfile-bookings-bookingInfo-button button {
        width: 100%;
        min-width: 20px;
        color: transparent;
    }

    .myProfile-bookings-bookingInfo-button button:after {
        color: red;
        content: "\00d7";
        display: inline-block;
        font-size: 30px;
        width: 10px;
        height: 10px;
        margin-top: -20px;
    }
}