.tournaments-box {
    background-color: rgba(211, 211, 211, 0.46);
}
.tournaments-contentContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    min-height: 100vh;
}
.tournaments-heroContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 500px;
    background-image: url('../../images/tournaments_hero_section.jpeg');
    background-size: cover;
    background-position: center;
}
.tournaments-heroSectionTitle {
    width: 100%;
    margin: auto;
    margin-top: 100px;
    height: 120px;
    color: white;
    font-size: 64px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-family: "Poppins",
        sans-serif;
    line-height: 70px;
}
.tournaments-heroSectionSubtitle {
    width: 90%;
    margin: auto;
    height: 150px;
    color: white;
    font-size: 64px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-family: "Poppins",
        sans-serif;
    line-height: 70px;
    margin-top: 50px;
}
.tournaments-topSection {
    height: 100%;
}
.tournaments-contentBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 100px;
}
.tournaments-contentBox-calendar {
    display: flex;
    width: 100%;
    margin-top: 50px;
    justify-content: center;   
}
.tournaments-contentBox-calendar-month {
    font-size: 30px;
    font-weight: 700;
    width: 30%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
}
.tournaments-contentBox-calendar-month-arrowButton {
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: gray;
    font-weight: 400;
}
.tournaments-contentBox-calendar-month-name {
    font-size: 30px;
}
.tournaments-contentBox-notification {
    margin-top: 20px;
    font-size: 17px;
    font-weight: 700;
    color: rgba(255, 81, 0, 0.957);
}
.tournaments-contentBox-tournamentList {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.tournaments-contentBox-noTournaments {
    margin-top: 20px;
    font-size: 20px;
}
.tournaments-selectionBox {
    display: flex;
    width: 80%;
    position: absolute;
    z-index: 1000;
    top: 405px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 180px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-image: url('../../images/sponsors_desk.JPG');
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 450px) {
    .tournaments-selectionBox {
        width: 90%;
        background-image: url('../../images/sponsors_mobile.JPG');
    }
    .tournaments-heroContainer {
        height: 300px;
    }
    .tournaments-heroSectionTitle {
        font-size: 25px;
        line-height: 25px;
        margin-top: 50px;
        height: 80px;
    }
    .tournaments-heroSectionSubtitle {
        font-size: 28px;
        line-height: 25px;
        margin-top: 10px;
    }
    .tournaments-selectionBox {
        margin-top: -180px;
    }
}

@media only screen and (max-width: 375px) {
    .tournaments-selectionBox {
        width: 90%;
        background-image: url('../../images/sponsors_mobile.JPG');
    }
    .tournaments-heroContainer {
        height: 300px;
    }
    
    .tournaments-heroSectionTitle {
        font-size: 25px;
        line-height: 25px;
        margin-top: 50px;
        height: 80px;
    }

    .tournaments-heroSectionSubtitle {
        font-size: 28px;
        line-height: 25px;
        margin-top: 10px;
    }
}
